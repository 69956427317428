import moment from 'moment';
import { array, date, fieldFrom, string, utcDateTransform } from '../api-helpers/responseMapping';

function toArray(data) {
    if (Array.isArray(data)) {
        return data;
    }

    if (data) {
        return [data];
    }

    return [];
}

export const organizationFieldsMap = (organization) => {
    const organizationMap = {
        name: '',
        brands: [],
        type: '',
        createdAt: '',
        userCount: 0,
        campaignCount: 0,
        orgId: '',
    };

    organizationMap.name = organization.organization_name;
    organizationMap.brands = toArray(organization.brands);
    organizationMap.type = organization.type;
    organizationMap.createdAt = organization.created_at;
    organizationMap.userCount = organization.user_count;
    organizationMap.campaignCount = organization.campaign_count;
    organizationMap.orgId = organization.org_id;

    if (organization.shared) {
        organizationMap.sharedOrg = organization.shared.map((org) => ({
            orgId: org.org_id,
            name: org.org_name,
            createdAt: org.shared_at,
            brands: [org.brand_name],
            brandId: org.brand_id,
            campaignCount: org.campaign_count,
            parentOrgId: organizationMap.orgId,
        }));
    }

    return organizationMap;
};

export const addOrgResponseMap = (org) => ({
    brands: [],
    state: org.state,
    name: org.name,
    orgId: org.org_id,
    sharedOrg: [],
    type: org.org_type,
    campaignCount: org.campaign_count,
    userCount: org.user_count,
    createdAt: moment.utc(org.createdAt).toISOString(),
    createdBy: org.created_by,
    updatedAt: moment.utc(org.updated_at).toISOString(),
    updatedBy: org.updated_by,
});

export const orgsAndBrandsFieldsMap = (org) => ({
    id: org.org_id,
    name: org.organization_name,
    type: org.type,
    brands: org.brands.map((brand) => ({
        id: brand.brand_id,
        name: brand.name,
        orgAccountId: brand.orgAccountId,
        ownerOrgId: brand.ownerOrgId,
        ownerOrgName: brand.ownerOrgName,
    })),
});

export const campaignMap = (campaign) => ({
    id: campaign.campaign_id,
    name: campaign.salesforce_campaign_name,
    brandName: campaign.brand_name,
    status: campaign.status,
    impressions: campaign.impressions,
    clicks: campaign.clicks,
    ctr: campaign.ctr,
    deliveredSpend: campaign.delivered_spend,
    revenue: campaign.total_revenue,
    roas: campaign.roas,
    startDate: campaign.start_date,
    endDate: campaign.end_date,
    type: campaign.campaign_type,
});

export const summaryItemMap = (item) => ({
    campaignType: item.campaign_type,
    campaignCount: item.campaign_count,
    impressions: item.impressions,
    clicks: item.clicks,
    ctr: item.ctr,
    deliveredSpend: item.delivered_spend,
    revenue: item.total_revenue,
    roas: item.roas,
});

export const summaryChartMap = (row) => ({
    date: row.date,
    clicks: row.clicks,
    impressions: row.impressions,
    deliveredSpend: row.delivered_spend,
    revenue: row.revenue_total,
    ctr: row.ctr,
});

export const summaryTotalsMap = (row) => ({
    campaignCount: row.campaign_count,
    clicks: row.clicks,
    impressions: row.impressions,
    deliveredSpend: row.delivered_spend,
    ctr: row.ctr,
});

export const userFieldsMap = (user) => {
    const userMap = {
        aid: '',
        uuid: '',
        first_name: '',
        last_name: '',
        email: '',
        roles: [],
        brandIds: [],
        created_at: '',
        updated_at: '',
        deleted: false,
        active: false,
        last_login: '',
        created_by: null,
        isExtUser: true,
        permissions: [],
        exp: null,
        iat: null,
    };

    userMap.aid = user?.aid;
    userMap.uuid = user?.uuid;
    userMap.first_name = user.first_name;
    userMap.last_name = user.last_name;
    userMap.email = user.email;
    userMap.roles = toArray(user?.roles);
    userMap.brandIds = toArray(user?.brandsIds);
    userMap.created_at = user.created_at;
    userMap.updated_at = user?.updated_at;
    userMap.deleted = user.deleted;
    userMap.active = user.active;
    userMap.last_login = user?.last_login;
    userMap.created_by = user?.created_by;
    userMap.isExtUser = user.isExtUser;
    userMap.permissions = toArray(user?.permissions);
    userMap.exp = user.exp;
    userMap.iat = user.iat;

    return userMap;
};

export const userFieldsMapV2 = (response) => {
    const userMap = {
        aid: '',
        userId: '',
        uuid: '',
        first_name: '',
        last_name: '',
        email: '',
        state: '',
        roles: [],
        created_at: '',
        updated_at: '',
        deleted: false,
        active: false,
        last_login: '',
        created_by: null,
        creatorName: '',
        organizationName: '',
        orgAccounts: [],
        brandNames: [],
        brandIds: [],
        isExtUser: false,
        permissions: [],
        exp: null,
        iat: null,
    };

    userMap.uuid = response.payload.userId;
    userMap.userId = response.payload.userId;
    userMap.aid = response.payload.aid;
    userMap.organizationId = response.payload.orgId;
    userMap.first_name = response.payload.firstName;
    userMap.last_name = response.payload.lastName;
    userMap.email = response.payload.email;
    userMap.state = response.payload.state;
    userMap.roles = toArray(response.payload?.roleNames);
    userMap.created_at = response.payload?.createdAt;
    userMap.created_by = response.payload?.createdBy;
    userMap.updated_at = response.payload?.updatedAt;
    userMap.updated_by = response.payload?.updatedBy;
    userMap.deleted = response.payload.state === 'archived';
    userMap.active = response.payload.state === 'active';
    userMap.last_login = response.payload?.lastLogin;
    userMap.creatorName = response.payload.creatorName;
    userMap.organizationName = response.payload.orgName;
    userMap.brandNames = toArray(response.payload.brandNames);
    userMap.brandIds = toArray(response.payload?.brandIds);
    userMap.orgAccounts = toArray(response.payload?.orgAccounts);
    userMap.isExtUser = !response.payload?.aid;
    userMap.permissions = toArray(response.payload.permissions);
    userMap.exp = response.exp;
    userMap.iat = response.iat;

    return userMap;
};

// TODO consolidate
export const formatUser = (user) => ({
    uuid: user.userId,
    aid: user.aid,
    created_at: user.createdAt,
    updated_at: user.updatedAt,
    email: user.email,
    first_name: user.firstName,
    last_name: user.lastName,
    invite_state: user.inviteState,
    brandIds: user.brandIds || [],
    created_by_name: user.creatorName,
    roles: user.roleNames,
    user_status: user.userStatus,
    last_login: user.lastLogin,
    active: user.state === 'active',
    deleted: user.state === 'archived',
    created_by: user.createdBy,
    orgAccounts: user?.orgAccounts ? user?.orgAccounts : null,
    isExtUser: user.isExtUser,
    organizationName: user.orgName,
    organizationId: user.orgId,
    brandNames: user.brandNames,
});

export const formatUpdatedUser = (user) => ({
    uuid: user.userId,
    aid: user.aid,
    created_at: user.createdAt,
    updated_at: user.updatedAt,
    email: user.email,
    first_name: user.firstName,
    last_name: user.lastName,
    invite_state: user.inviteState,
    created_by_name: user.creatorName,
    roles: user.roleNames,
    user_status: user.userStatus,
    last_login: user.lastLogin,
    active: user.state === 'active',
    deleted: user.state === 'archived',
    created_by: user.createdBy,
    orgAccounts: user?.orgAccounts ? user?.orgAccounts : null,
    organizationName: user.orgName,
    organizationId: user.orgId,
});

export const formatUpdatedUserBrands = (user) => ({
    uuid: user.userId,
    aid: user.aid,
    created_at: user.createdAt,
    updated_at: user.updatedAt,
    email: user.email,
    first_name: user.firstName,
    last_name: user.lastName,
    invite_state: user.inviteState,
    created_by_name: user.creatorName,
    roles: user.roleNames,
    user_status: user.userStatus,
    last_login: user.lastLogin,
    active: user.state === 'active',
    deleted: user.state === 'archived',
    created_by: user.createdBy,
    orgAccounts: user?.orgAccounts ? user?.orgAccounts : null,
    brandIds: user?.brandIds,
    organizationName: user.orgName,
    organizationId: user.orgId,
    brandNames: user.brandNames,
});

export const brandSchema = {
    id: fieldFrom('brand_id', string()),
    name: string(),
    state: string(),
    orgNames: [string()],
    createdAt: date().transform(utcDateTransform),
    updatedAt: date().transform(utcDateTransform),
    createdBy: string(),
    updatedBy: string(),
};

export const accountSchema = {
    createdAt: date().transform(utcDateTransform),
    createdBy: string(),
    id: string(),
    myadsBrandId: fieldFrom('brandId', string()),
    myadsBrandName: fieldFrom('brandName', string()),
    orgAccountId: string(),
    providerAccountId: string(),
    providerAccountName: string(),
    providerAccountOrgId: fieldFrom('orgId', string()),
    providerAccountOrgName: fieldFrom('orgName', string()),
    providerAccountType: string(),
    providerName: fieldFrom('provider', string()),
    state: string(),
    updatedAt: date().transform(utcDateTransform),
    updatedBy: string(),
};

// Unlike other brand endpoints, brand list returns brand objects with camel case keys
export const brandListSchema = [
    {
        ...brandSchema,
        id: fieldFrom('brandId', string()),
    },
];

export const addBrandSchema = {
    ...brandSchema,
    orgNames: array(string()).default([]),
};

export const updateBrandSchema = {
    // Fields needed for update actions
    id: brandSchema.id,
    updatedAt: brandSchema.updatedAt,
    updatedBy: brandSchema.updatedBy,

    // Fields that can be updated
    name: brandSchema.name,
};

export const accountListSchema = [
    {
        ...accountSchema,
    },
];

export const addAccountSchema = {
    ...accountSchema,
};

export const updateAccountSchema = {
    ...accountSchema,
};

export const cardDataMap = (cardData) => ({
    ...cardData,
    roas: cardData.roas !== undefined ? cardData.roas : cardData.roas_total,
});

export const summaryUnitMap = (units) => ({
    date: units.date || 'date',
    clicks: units.clicks || 'integer',
    impressions: units.impressions || 'integer',
    deliveredSpend: units.delivered_spend || 'currency',
    revenue: units.revenue_total || 'currency',
    ctr: units.ctr || 'percent',
    roas: units.roas_total || 'integer',
});

export const filterMap = (filterObj = {}) => {
    const filterMapped = {};

    if (Object.keys(filterObj).length) {
        filterMapped.brands = filterObj.filter?.brands;
        filterMapped.categories = filterObj.filter?.categories;
        filterMapped.campaignTypes = filterObj.filter?.campaignTypes;
        filterMapped.status = filterObj.filter?.status;

        if (filterObj.filter?.organizations) {
            filterMapped.organizations = filterObj.filter.organizations;
        }

        return { filterId: filterObj.filter_id, savedFilter: filterMapped };
    }

    return filterObj;
};
